import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { datadogRum } from '@datadog/browser-rum';
import { selectShipper } from 'containers/Base/selectors'

export const useSentryUser = () => {
  const { id: shipperId } = useSelector(selectShipper()) || {}

  useEffect(() => {
    if (shipperId) {
      Sentry.setUser({ id: shipperId.toString() })

      datadogRum.setUser({
        id: shipperId.toString(),
      })
    } else {
      Sentry.setUser(null)

      datadogRum.clearUser()
    }
  }, [shipperId])
}
