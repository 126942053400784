import { VerticalStepper } from '@nv/react-akira'
import styled from 'styled-components'
import { RadioGroup } from 'components/RadioGroup'
import { Colors, Fonts } from '../../themes'
import { Radio, Row, Col, NVInput, Icon } from '@nv/react-commons/src/Components'
import { Tabs } from 'antd/lib'
import CircleIcon from '../../components/CircleIcon'
import { Link } from 'components/Link'
import { TabsProps } from 'antd/lib/tabs'
import { IconButton } from 'components/IconButton'
import { media } from 'themes'
import { VolumePicker } from 'components/VolumePicker'
import Button from 'components/Button'
import { Text } from 'components/Text'
import { Card } from 'components/Card'

interface TextColProps {
  first?: boolean
}

interface CustomLinkProps {
  lineHeight?: number
  disabled?: boolean
}

interface ButtonProps {
  disabled?: boolean
}

interface PointerProps {
  active?: boolean
}

export const BCWrapper = styled.div`
  padding: 24px 120px;
  overflow: auto;
`

export const VerticalStepperContainer = styled(VerticalStepper)`
  li > div {
    width: 100%;
  }
`

export const StyledRadioGroup = styled(RadioGroup)`
  .ant-radio-wrapper .ant-radio + span {
    padding: 0 12px;
  }

  margin-bottom: 8px;
`
export const StyledRadio = styled(Radio)`
  && {
    display: flex;
    flex: 1;
    min-width: 0;
    line-height: 16px;
    margin-bottom: 0;

    & > span:last-child {
      width: 100%;
    }

    span.ant-radio + * {
      padding-left: 12px;
    }
  }
`
export const SpacedBetweenFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`

export const StyledImage = styled.img`
  width: 80px;
`

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    letter-spacing: normal;
    height: 160px;
    width: 100%;
    padding: 12px 20px 20px;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:hover,
    &:hover div {
      border-color: ${Colors.paleGrey};
      color: ${Colors.nvPriRed};
      transition: all 0.3s;
    }
  }
`

export const StyledCircleIcon = styled(CircleIcon)`
  margin: 0 auto 12px;
`

export const TextCol = styled.div<TextColProps>`
  position: relative;
  ${({ first }) => first && 'width: 100px;'}
  ${({ color }) => `color: ${color || Colors.greyishBrown}`}
`

export const TextWrapper = styled.div`
  ${({ color }) => `color: ${color || Colors.greyishBrown}`};
  display: flex;
  justify-content: space-between;
  flex: 1;
  ${({ width }: { width?: number }) => `max-width: ${width || 250}`}px;
  margin-right: 20px;
`

export const CustomLink = styled(Link)<CustomLinkProps>`
  line-height: ${({ lineHeight }) => lineHeight || 1.5};
  display: block;
  margin: 0 auto;
  width: fit-content;
  &[disabled] {
    pointer-events: auto;
    &:hover {
      color: rgba(0, 0, 0, 0.25);
    }
  }
`

export const StyledTabs = styled(Tabs)<TabsProps>`
  && {
    .ant-tabs-ink-bar {
      display: none !important;
    }

    .ant-tabs-bar {
      padding: 24px 0;
      border: none;
    }

    .ant-tabs-nav-wrap {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    .ant-tabs-nav .ant-tabs-tab {
      text-align: center;
      margin: 1px 0;
      padding: 4px 16px;
      min-width: 300px;
      background: ${Colors.white};
      border: 1px solid ${Colors.N200};
    }

    .ant-tabs-nav .ant-tabs-tab-active {
      border: 1px solid transparent;
      background: ${Colors.balanceTxtBold};
      color: ${Colors.white};
    }
  }
`

export const StyledIconButton = styled(IconButton).attrs({
  type: 'medium'
})`
  ${media.min.tablet`margin-left: 32px;`} ${media.max.tablet`margin-left: 16px;`};
`

export const StyleConfirmdButton = styled(Button)<ButtonProps>`
  && {
    height: 36px;
    ${({ disabled }) =>
      disabled
        ? `
      cursor: not-allowed;
      color: ${Colors.disabled} !important;
      background-color: ${Colors.lightGreyBlue} !important;
      border-color: ${Colors.lightGreyBlue} !important;
    `
        : ''}
  }
`

export const StyledIcon = styled(Icon)`
  margin-right: 18px;
`

export const StyledLink = styled(Link)`
  color: ${Colors.greyishBrown};
  ${Fonts.style.bold};
  text-decoration: none;
  margin-top: 24px;
  line-height: 0;
`

export const DataListWrapper = styled.div`
  .ant-radio-group {
    max-height: 320px;
    overflow: auto;
    width: 100%;
    padding-bottom: 15px;
  }
  > a {
    padding-top: 15px;
  }
`

export const StyledVolumePicker = styled(VolumePicker)`
  margin-top: 24px;
`

export const HorizontalSeperateLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${Colors.lightPeriwinkle};
`

export const BoxCol = styled(Col)`
  > div {
    div {
      height: auto !important;
      width: auto !important;
      padding: 2px;
    }
  }
`

export const TableHeaderRow = styled(Row)`
  background: ${Colors.lightPeriwinkle};
  padding-top: 8px;
  padding-bottom: 8px;
`

export const ShortText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  ${media.min.tablet`width: 140px;`} ${media.max.tablet`width: 200px;`};
`

export const CancelButton = styled(Button)`
  margin-right: 4px;
`

export const PaddingRow = styled(Row)`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const SmallInput = styled(NVInput)`
  &&& {
    height: 28px;
  }
`

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.lightPeriwinkle};
  padding: 14px 16px;
`

export const PaddingText = styled(Text)`
  padding-top: 12px;
`

export const StyledMinCard = styled(Card)`
  min-height: 304px;
`

export const StyledRollDiv = styled.div`
  width: auto;
  height: 360px;
  overflow: auto;
`

export const ButtonFullWidth = styled(Button)`
  margin-top: 12px;
  width: 100%;
`

export const InlineText = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 12px;
  color: ${Colors.lightGreyBlue};
`

export const EndedDivBlock = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const NoMarginIcon = styled(Icon)`
  margin-right: 0;
`

export const PointerGroup = styled.div<PointerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  cursor: pointer;
  ${({ active }) => active && 'background: #fcf5f6'}
`

export const MissingMessage = styled.div`
  background: #fff7f0;
  border: 1px solid #f06c00;
  border-radius: 4px;
  padding: 4px 6px 4px 10px;
  display: flex;
  align-items: center;
  gap: 6px;
`

export const HoverName = styled(Text)`
  margin-left: 6px;
  &:hover {
    bold: 700;
    color: #e50239;
  }
`

export const InputZone = styled.div`
  background: #f8f8f8;
  padding: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`

export const InlineDiv = styled.div`
  display: flex;
  align-items: center;
`

export const DisabledIcon = styled(NoMarginIcon)`
  cursor: not-allowed;
  color: #cacaca;
`
