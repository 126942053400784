/* eslint-disable max-len */
import { VolumePicker } from 'components/VolumePicker'

export const MIN_TID_LENGTH = 1
export const MAX_TID_LENGTH = 29

export const SERVICE_TYPE = {
  LM: 1,
  CC: 2,
  CCLM: 3,
  MMCCLM: 4,
  MMCC: 5,
  E2E: 6
}

export const SERVICE_TYPE_LABEL = {
  [SERVICE_TYPE.LM]: 'LM',
  [SERVICE_TYPE.CC]: 'CC',
  [SERVICE_TYPE.CCLM]: 'CCLM',
  [SERVICE_TYPE.MMCCLM]: 'MMCCLM',
  [SERVICE_TYPE.MMCC]: 'MMCC',
  [SERVICE_TYPE.E2E]: 'E2E'
}
export const MAX_UPLOAD_ORDER_REQUEST = 1000
export const MAX_CONTENT_LENGTH = 1000
export const DETAIL_SUB_STEP = {
  NONE: 'NONE',
  SERVICE: 'SERVICE',
  PICKUP: 'PICKUP',
  RETURN_ADDRESS: 'RETURN_ADDRESS',
  PICKUP_ADDRESS: 'PICKUP_ADDRESS',
  TIME: 'TIME',
  VOLUME: 'VOLUME',
  CUSTOM_CURRENCY: 'CUSTOM_CURRENCY',
  SENDER_INFO: 'SENDER_INFO'
}

export const VOLUME_VALUE = {
  [VolumePicker.LESS_THAN_3_PARCELS]: 4,
  [VolumePicker.LESS_THAN_10_PARCELS]: 3,
  [VolumePicker.HALF_VAN]: 1,
  [VolumePicker.FULL_VAN]: 0,
  [VolumePicker.MORE_THAN_ONE_VAN]: 2
}
export const DEFAULT_VOLUME = VolumePicker.LESS_THAN_3_PARCELS
export const INPUT_METHODS = {
  KEYBOARD: 'KEYBOARD',
  FILE: 'FILE',
  NONE: 'NONE'
}

export const PICKUP_ADDRESS = {
  START: 0,
  LIMIT: 50,
  DELAY_TIME: 250
}

export const DEBOUNCE_CLICK = 250
export const ORDER_CREATE_SOURCE = {
  KEYBOARD: 7,
  CSV: 8
}
export const COUNTRY_LABEL = {
  CN: 'China'
}
export const ALLOWED_COUNTRY = {
  SG: 'SG',
  MY: 'MY',
  TH: 'TH',
  VN: 'VN',
  ID: 'ID',
  PH: 'PH'
}
export const ALLOWED_COUNTRY_STATE_PROVINCE = {
  MY: 'MY',
  TH: 'TH',
  ID: 'ID',
  PH: 'PH'
}

export const ORDER_ITEMS_HEIGHT = 48

export const NO_PICKUP_NEEDED = 'No pickup needed'
export const TIME_SCHEDULED = 'Time scheduled'

export const CREATION_METHODS = {
  [ORDER_CREATE_SOURCE.CSV]: 'Bulk',
  [ORDER_CREATE_SOURCE.KEYBOARD]: 'Manual'
}

export const NUMBER_OF_ROWS = 3

export const DEFAULT_EMPTY_NUMBER = 1

export const MAX_UPLOAD_ITEMS_IN_ONE_ORDER_REQUEST = 100

export const ITEMISED_COUNTRY_CODES = {
  ID: 'Indonesia',
  VN: 'Vietnam',
  SG: 'Singapore',
  MY: 'Maylaysia',
  TH: 'Thailand'
}

export const GOODS_CURRENCY_DATA = {
  IDR: 'idr',
  MYR: 'myr',
  PHP: 'php',
  SGD: 'sgd',
  THB: 'thb',
  USD: 'usd',
  VND: 'vnd'
}
export const CURRENY_COUNTRY_LABELS = {
  SG: 'sgd',
  MY: 'myr',
  TH: 'thb',
  VN: 'vnd',
  ID: 'idr',
  PH: 'php'
}
export const CUSTOM_CURRENCY_LIST = [
  {
    label: GOODS_CURRENCY_DATA.IDR,
    key: GOODS_CURRENCY_DATA.IDR,
    desc: 'indonesian_rupiah'
  },
  {
    label: GOODS_CURRENCY_DATA.MYR,
    key: GOODS_CURRENCY_DATA.MYR,
    desc: 'malaysian_ringgit'
  },
  {
    label: GOODS_CURRENCY_DATA.PHP,
    key: GOODS_CURRENCY_DATA.PHP,
    desc: 'philippine_peso'
  },
  {
    label: GOODS_CURRENCY_DATA.SGD,
    key: GOODS_CURRENCY_DATA.SGD,
    desc: 'singapore_dollar'
  },
  {
    label: GOODS_CURRENCY_DATA.THB,
    key: GOODS_CURRENCY_DATA.THB,
    desc: 'thailand_baht'
  },
  {
    label: GOODS_CURRENCY_DATA.USD,
    key: GOODS_CURRENCY_DATA.USD,
    desc: 'dollar'
  },
  {
    label: GOODS_CURRENCY_DATA.VND,
    key: GOODS_CURRENCY_DATA.VND,
    desc: 'vienam_dong'
  }
]
export const SUB_STEP_KEY = {
  PICKUP: 'pickup',
  PICKUP_ADDRESS: 'pickupAddress',
  RETURN_ADDRESS: 'returnAddress',
  TIME_SLOT: 'timeSlot',
  SELECTED_VOLUME: 'selectedVolume',
  CUSTOM_CURRENCY: 'customsCurrency'
}
export const NUMBER_OF_USELESS_LINE = 2
export const getKeysTab = isItemised => ({
  VALID_ORDER: {
    LABEL: isItemised ? 'international_valid_items' : 'international_valid_order',
    KEY: 'VALID_ORDERS'
  },
  INVALID_ORDER: {
    LABEL: isItemised ? 'international_invalid_items' : 'international_invalid_order',
    KEY: 'INVALID_ORDER'
  },
  ALL_ORDERS: {
    LABEL: isItemised ? 'international_total_items' : 'international_total_order',
    KEY: 'ALL_ORDERS'
  }
})

export const ERROR_CODE_MAPPING = {
  REQUIRED: 1,
  INVALID: 2,
  DUPLICATE: 3,
  DIFFERENT_ORIGIN: 4
}

export const START_GETTING_DATA_INDEX = 1
export const FIELD_KEYS = {
  REQUESTED_TRACKING_ID: 'Requested Tracking ID',
  SOURCE_ORDER_ID: 'Source Order ID (Optional)',
  TO_NAME: 'To Name',
  TO_ADDRESS: 'To Address',
  TO_CITY: 'To City',
  TO_STATE_PROVINCE: 'To State/Province',
  TO_POSTCODE: 'To Postcode',
  TO_EMAIL: 'To Email (Optional)',
  TO_CONTACT_NUMBER: 'To Contact Number',
  GOOD_DESCRIPTION: 'Goods Description',
  GOOD_VALUE: 'Goods Value',
  COD: 'COD (Optional)',
  CONSIGNEE_TAX: 'Consignee Tax Number',
  QUANTITY: 'Item Quantity',
  ITEM_DESCRIPTION: 'Item Description',
  ITEM_VALUE: 'Item Value',
  ROW_START: 'Row#',
  GST_INCLUDED: 'GST Included',
  GST_NUMBER: 'GST Number',
  PARCEL_WEIGHT: 'Parcel Weight (Optional)',
  DELIVERY_INSTRUCTION: 'Delivery Instruction (Optional)',
  INSURED_VALUE: 'Insured Value (Optional)',
  LVG_NUMBER: 'LVG Number',
  ITEM_COLOR: 'Item Color (Optional)',
  ITEM_WEIGHT: 'Item Weight (Optional)'
}

export const INTERNATIONAL_COMMON_DATA = {
  [FIELD_KEYS.TO_NAME]: 'Louise',
  [FIELD_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
  [FIELD_KEYS.TO_CITY]: 'Wilayah Persekuatuan',
  [FIELD_KEYS.TO_STATE_PROVINCE]: 'Kuala Lumpur',
  [FIELD_KEYS.TO_POSTCODE]: '50450',
  [FIELD_KEYS.TO_EMAIL]: '',
  [FIELD_KEYS.TO_CONTACT_NUMBER]: '386816556'
}

export const EXTENDED_INTERNATIONAL_COMMON_DATA = {
  [FIELD_KEYS.INSURED_VALUE]: '',
  [FIELD_KEYS.PARCEL_WEIGHT]: '',
  [FIELD_KEYS.SOURCE_ORDER_ID]: '',
  [FIELD_KEYS.DELIVERY_INSTRUCTION]: ''
}

export const EXAMPLE_INTERNATIONAL_DATA_ROW_1 = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
  ...INTERNATIONAL_COMMON_DATA,
  [FIELD_KEYS.GOOD_DESCRIPTION]: 'Sunglasses',
  [FIELD_KEYS.GOOD_VALUE]: '30.43',
  [FIELD_KEYS.COD]: '',
  ...EXTENDED_INTERNATIONAL_COMMON_DATA
}

export const EXAMPLE_INTERNATIONAL_DATA_ROW_2 = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345679',
  ...INTERNATIONAL_COMMON_DATA,
  [FIELD_KEYS.GOOD_DESCRIPTION]: 'T-shirt',
  [FIELD_KEYS.GOOD_VALUE]: '30.43',
  [FIELD_KEYS.COD]: '',
  ...EXTENDED_INTERNATIONAL_COMMON_DATA
}

export const EXAMPLE_DATA_BY_COUNTRY = {
  SG: {
    ROW_1: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      [FIELD_KEYS.TO_NAME]: 'Louise',
      [FIELD_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
      [FIELD_KEYS.TO_POSTCODE]: '50450',
      [FIELD_KEYS.TO_EMAIL]: '',
      [FIELD_KEYS.TO_CONTACT_NUMBER]: '386816556',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.GST_INCLUDED]: 'No',
      [FIELD_KEYS.GST_NUMBER]: '123456789',
      [FIELD_KEYS.COD]: '',
      [FIELD_KEYS.INSURED_VALUE]: '',
      [FIELD_KEYS.PARCEL_WEIGHT]: '',
      [FIELD_KEYS.SOURCE_ORDER_ID]: '',
      [FIELD_KEYS.DELIVERY_INSTRUCTION]: ''
    },
    ROW_2: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      [FIELD_KEYS.TO_NAME]: 'Louise',
      [FIELD_KEYS.TO_ADDRESS]: '149, Jin Ampang, Kuala Lumpur, 50450',
      [FIELD_KEYS.TO_POSTCODE]: '50450',
      [FIELD_KEYS.TO_EMAIL]: '',
      [FIELD_KEYS.TO_CONTACT_NUMBER]: '386816556',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'T-shirt',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.GST_INCLUDED]: 'Yes',
      [FIELD_KEYS.GST_NUMBER]: '123456789',
      [FIELD_KEYS.COD]: '',
      [FIELD_KEYS.INSURED_VALUE]: '',
      [FIELD_KEYS.PARCEL_WEIGHT]: '',
      [FIELD_KEYS.SOURCE_ORDER_ID]: '',
      [FIELD_KEYS.DELIVERY_INSTRUCTION]: ''
    }
  },
  MY: {
    ROW_1: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.LVG_NUMBER]: '123456789',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    },
    ROW_2: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'T-shirt',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.LVG_NUMBER]: '123456789',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    }
  },
  VN: {
    ROW_1: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.CONSIGNEE_TAX]: '123456789',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    },
    ROW_2: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.CONSIGNEE_TAX]: '123456789',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'T-shirt',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    }
  },
  ID: {
    ROW_1: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.CONSIGNEE_TAX]: '123456789',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    },
    ROW_2: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.CONSIGNEE_TAX]: '123456789',
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'T-shirt',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.COD]: '',
      ...EXTENDED_INTERNATIONAL_COMMON_DATA
    }
  },
  PH: {
    ROW_1: EXAMPLE_INTERNATIONAL_DATA_ROW_1,
    ROW_2: EXAMPLE_INTERNATIONAL_DATA_ROW_2
  },
  TH: {
    ROW_1: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'Sunglasses',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.ITEM_COLOR]: 'White',
      [FIELD_KEYS.ITEM_WEIGHT]: '0.5',
      [FIELD_KEYS.COD]: '',
      [FIELD_KEYS.INSURED_VALUE]: '',
      [FIELD_KEYS.SOURCE_ORDER_ID]: '',
      [FIELD_KEYS.DELIVERY_INSTRUCTION]: ''
    },
    ROW_2: {
      [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'NJV12345678',
      ...INTERNATIONAL_COMMON_DATA,
      [FIELD_KEYS.QUANTITY]: 1,
      [FIELD_KEYS.ITEM_DESCRIPTION]: 'T-shirt',
      [FIELD_KEYS.ITEM_VALUE]: '30.43',
      [FIELD_KEYS.ITEM_COLOR]: 'Red',
      [FIELD_KEYS.ITEM_WEIGHT]: '1.0',
      [FIELD_KEYS.COD]: '',
      [FIELD_KEYS.INSURED_VALUE]: '',
      [FIELD_KEYS.SOURCE_ORDER_ID]: '',
      [FIELD_KEYS.DELIVERY_INSTRUCTION]: ''
    }
  }
}

export const EXTEND_INSTRUCTION_COMMON_DATA = {
  [FIELD_KEYS.INSURED_VALUE]:
    'Intruction:\nInsured value of goods must be a number greater than 0 and in destination currency',
  [FIELD_KEYS.PARCEL_WEIGHT]: 'Instruction:\nParcel weight should be greater than 0 and in kg',
  [FIELD_KEYS.SOURCE_ORDER_ID]: 'Instructions:\nTracking number for your internal referrence',
  [FIELD_KEYS.DELIVERY_INSTRUCTION]: 'Instruction:\nDelivery instruction may contain up to 1000 characters'
}

export const INSTRUCTION_DATA = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
  [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
  [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
  [FIELD_KEYS.TO_CITY]:
    'Instruction: City is required for all countries except SG, and must be less than 255 characters long.',
  [FIELD_KEYS.TO_STATE_PROVINCE]:
    'Instruction: Required for all countries except SG. State is required for MY, province is required for PH and TH.\nMust be less than 255 characters long.',
  [FIELD_KEYS.TO_POSTCODE]:
    'Instruction:\nPostcode is required for SG, MY and TH and must be a number of the valid format:\n- SG: 6 digits\n- MY,TH: 5 digits\n- PH: 4 digits',
  [FIELD_KEYS.TO_EMAIL]:
    'Instruction: Email address is optional. If entered, it must be of the valid format.For example: user@example.com.',
  [FIELD_KEYS.TO_CONTACT_NUMBER]:
    'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
  [FIELD_KEYS.GOOD_DESCRIPTION]: 'Instruction: Goods description is required and may contain up to 1000 characters.',
  [FIELD_KEYS.GOOD_VALUE]: 'Instruction: Goods value is required and must be a number greater than 1.',
  [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
  ...EXTEND_INSTRUCTION_COMMON_DATA
}

export const ITEMISED_INSTRUCTION_COMMON_DATA = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
  [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
  [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
  [FIELD_KEYS.TO_CITY]: 'Instruction: City is required and must be less than 255 characters long.',
  [FIELD_KEYS.TO_STATE_PROVINCE]:
    'Instruction: Required for all countries except SG. Province is required for ID and VN, and must be less than 255 characters long.',
  [FIELD_KEYS.TO_POSTCODE]:
    'Instruction:\nPostcode is optional and must be a number of the valid format:\n- VN: 6 digits\n- ID: 5 digits',
  [FIELD_KEYS.TO_EMAIL]:
    'Instruction: Email address is optional. If entered, it must have valid format. For example: user@example.com.',
  [FIELD_KEYS.TO_CONTACT_NUMBER]:
    'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
  [FIELD_KEYS.CONSIGNEE_TAX]:
    'Instruction:\nConsignee tax number is required if shipping to ID and VN to get a tax discount, and must be a number.',
  [FIELD_KEYS.QUANTITY]: 'Instruction: Quantity is required and must be a number greater than 0.',
  [FIELD_KEYS.ITEM_DESCRIPTION]: 'Instruction: Item description is required and may contain up to 1000 characters.',
  [FIELD_KEYS.ITEM_VALUE]: 'Instruction: Item value is required and must be a number greater than 1.'
}

export const INSTRUCTION_DATA_BY_COUNTRY = {
  SG: {
    [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
    [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_POSTCODE]:
      'Instruction:\nPostcode is required and must be a number of the valid format:\n- SG: 6 digits',
    [FIELD_KEYS.TO_EMAIL]:
      'Instruction: Email address is optional. If entered, it must have valid format. For example: user@example.com.',
    [FIELD_KEYS.TO_CONTACT_NUMBER]:
      'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
    [FIELD_KEYS.QUANTITY]: 'Instruction: Quantity is required and must be a number greater than 0.',
    [FIELD_KEYS.ITEM_DESCRIPTION]: 'Instruction: Item description is required and may contain up to 1000 characters.',
    [FIELD_KEYS.ITEM_VALUE]: 'Instruction: Item value is required and must be a number greater than 1.',
    [FIELD_KEYS.GST_INCLUDED]:
      'Instruction:\nEnter "Yes" if GST is included in the item\'s value or "No" if it\'s not included. If left blank, we assume that GST is not included.',
    [FIELD_KEYS.GST_NUMBER]:
      'Instruction:\nGoods and Services Tax (GST) number is required on GST included item to prevent double taxation when importing goods into Singapore.',
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  MY: {
    [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
    [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_CITY]: 'Instruction: City is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_STATE_PROVINCE]:
      'Instruction: State is required for Malaysia.\nMust be less than 255 characters long.',
    [FIELD_KEYS.TO_POSTCODE]:
      'Instruction:\nPostcode is required and must be a number of the valid format:\n- MY: 5 digits',
    [FIELD_KEYS.TO_EMAIL]:
      'Instruction: Email address is optional. If entered, it must have valid format. For example: user@example.com.',
    [FIELD_KEYS.TO_CONTACT_NUMBER]:
      'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
    [FIELD_KEYS.QUANTITY]: 'Instruction: Quantity is required and must be a number greater than 0.',
    [FIELD_KEYS.ITEM_DESCRIPTION]: 'Instruction: Item description is required and may contain up to 1000 characters.',
    [FIELD_KEYS.ITEM_VALUE]: 'Instruction: Item value is required and must be a number greater than 1.',
    [FIELD_KEYS.LVG_NUMBER]:
      'Instruction:\nLow Valuable Goods (LVG) number is provided to prevent double taxation when importing goods into Malaysia.',
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  VN: {
    ...ITEMISED_INSTRUCTION_COMMON_DATA,
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  ID: {
    ...ITEMISED_INSTRUCTION_COMMON_DATA,
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  PH: {
    [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
    [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_CITY]:
      'Instruction: City is required for all countries except SG, and must be less than 255 characters long.',
    [FIELD_KEYS.TO_STATE_PROVINCE]:
      'Instruction: Required for all countries except SG. State is required for MY, province is required for PH and TH.\nMust be less than 255 characters long.',
    [FIELD_KEYS.TO_POSTCODE]:
      'Instruction:\nPostcode is optional and must be a number of the valid format:\n- PH: 4 digits',
    [FIELD_KEYS.TO_EMAIL]:
      'Instruction: Email address is optional. If entered, it must be of the valid format.For example: user@example.com.',
    [FIELD_KEYS.TO_CONTACT_NUMBER]:
      'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
    [FIELD_KEYS.GOOD_DESCRIPTION]: 'Instruction: Goods description is required and may contain up to 1000 characters.',
    [FIELD_KEYS.GOOD_VALUE]: 'Instruction: Goods value is required and must be a number greater than 1.',
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    ...EXTEND_INSTRUCTION_COMMON_DATA
  },
  TH: {
    [FIELD_KEYS.REQUESTED_TRACKING_ID]: `Instruction:\nRequested tracking ID must be ${MIN_TID_LENGTH}-${MAX_TID_LENGTH} characters long`,
    [FIELD_KEYS.TO_NAME]: 'Instruction: Name is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_ADDRESS]: 'Instruction: Address is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_CITY]: 'Instruction: City is required and must be less than 255 characters long.',
    [FIELD_KEYS.TO_STATE_PROVINCE]:
      'Instruction: Required for all countries except SG. Province is required for ID and VN, and must be less than 255 characters long.',
    [FIELD_KEYS.TO_POSTCODE]:
      'Instruction:\nPostcode is optional and must be a number of the valid format:\n- VN: 6 digits\n- ID: 5 digits',
    [FIELD_KEYS.TO_EMAIL]:
      'Instruction: Email address is optional. If entered, it must have valid format. For example: user@example.com.',
    [FIELD_KEYS.TO_CONTACT_NUMBER]:
      'Instruction:\nContact number is required. The number must be 6-32 characters long, the first character can be a plus (+), and all following characters must be digits between 0 and 9.',
    [FIELD_KEYS.QUANTITY]: 'Instruction: Quantity is required and must be a number greater than 0.',
    [FIELD_KEYS.ITEM_DESCRIPTION]: 'Instruction: Item description is required and may contain up to 1000 characters.',
    [FIELD_KEYS.ITEM_VALUE]: 'Instruction: Item value is required and must be a number greater than 1.',
    [FIELD_KEYS.ITEM_COLOR]: 'Instruction: Color value must be less than 20 characters long.',
    [FIELD_KEYS.ITEM_WEIGHT]: 'Instruction: Weight value must be a number greater than 0.',
    [FIELD_KEYS.COD]: 'Instruction: COD value must be a number.',
    [FIELD_KEYS.INSURED_VALUE]:
      'Intruction:\nInsured value of goods must be a number greater than 0 and in destination currency',
    [FIELD_KEYS.SOURCE_ORDER_ID]: 'Instructions:\nTracking number for your internal referrence',
    [FIELD_KEYS.DELIVERY_INSTRUCTION]: 'Instruction:\nDelivery instruction may contain up to 1000 characters'
  }
}

const COMMON_REQUEST_FIELDS = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'requested_tracking_id',
  [FIELD_KEYS.TO_NAME]: 'to.name',
  [FIELD_KEYS.TO_ADDRESS]: 'to.address_line1',
  [FIELD_KEYS.TO_CITY]: 'to.city',
  [FIELD_KEYS.TO_STATE_PROVINCE]: 'to.state_province',
  [FIELD_KEYS.TO_POSTCODE]: 'to.post_code',
  [FIELD_KEYS.TO_EMAIL]: 'to.contact_email',
  [FIELD_KEYS.TO_CONTACT_NUMBER]: 'to.contact_number'
}

const EXTEND_COMMON_REQUEST_FIELDS = {
  [FIELD_KEYS.INSURED_VALUE]: 'delivery.insured_value',
  [FIELD_KEYS.PARCEL_WEIGHT]: 'parcel_details.weight',
  [FIELD_KEYS.SOURCE_ORDER_ID]: 'source_order_id',
  [FIELD_KEYS.DELIVERY_INSTRUCTION]: 'delivery.delivery_instructions'
}

export const INTERNATIONAL_REQUEST_FIELDS = {
  ...COMMON_REQUEST_FIELDS,
  [FIELD_KEYS.GOOD_DESCRIPTION]: 'parcel_details.customs_description',
  [FIELD_KEYS.GOOD_VALUE]: 'parcel_details.value',
  [FIELD_KEYS.COD]: 'delivery.cash_on_delivery',
  ...EXTEND_COMMON_REQUEST_FIELDS
}

export const INTERNATIONAL_ITEMISED_REQUEST_FIELDS = {
  ...COMMON_REQUEST_FIELDS,
  [FIELD_KEYS.CONSIGNEE_TAX]: 'parcel_details.tax_id',
  [FIELD_KEYS.QUANTITY]: 'item.quantity',
  [FIELD_KEYS.ITEM_DESCRIPTION]: 'item.description',
  [FIELD_KEYS.ITEM_VALUE]: 'item.unit_value',
  [FIELD_KEYS.COD]: 'delivery.cash_on_delivery',
  ...EXTEND_COMMON_REQUEST_FIELDS
}

export const INTERNATIONAL_SG_ITEMISED_REQUEST_FIELDS = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'requested_tracking_id',
  [FIELD_KEYS.TO_NAME]: 'to.name',
  [FIELD_KEYS.TO_ADDRESS]: 'to.address_line1',
  [FIELD_KEYS.TO_POSTCODE]: 'to.post_code',
  [FIELD_KEYS.TO_EMAIL]: 'to.contact_email',
  [FIELD_KEYS.TO_CONTACT_NUMBER]: 'to.contact_number',
  [FIELD_KEYS.QUANTITY]: 'item.quantity',
  [FIELD_KEYS.ITEM_DESCRIPTION]: 'item.description',
  [FIELD_KEYS.ITEM_VALUE]: 'item.unit_value',
  [FIELD_KEYS.GST_INCLUDED]: 'item.taxes.gst.is_included',
  [FIELD_KEYS.GST_NUMBER]: 'item.taxes.gst.number',
  [FIELD_KEYS.COD]: 'delivery.cash_on_delivery',
  ...EXTEND_COMMON_REQUEST_FIELDS
}

export const INTERNATIONAL_MY_ITEMISED_REQUEST_FIELDS = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: 'requested_tracking_id',
  [FIELD_KEYS.TO_NAME]: 'to.name',
  [FIELD_KEYS.TO_ADDRESS]: 'to.address_line1',
  [FIELD_KEYS.TO_CITY]: 'to.city',
  [FIELD_KEYS.TO_POSTCODE]: 'to.post_code',
  [FIELD_KEYS.TO_EMAIL]: 'to.contact_email',
  [FIELD_KEYS.TO_CONTACT_NUMBER]: 'to.contact_number',
  [FIELD_KEYS.QUANTITY]: 'item.quantity',
  [FIELD_KEYS.ITEM_DESCRIPTION]: 'item.description',
  [FIELD_KEYS.ITEM_VALUE]: 'item.unit_value',
  [FIELD_KEYS.COD]: 'delivery.cash_on_delivery',
  [FIELD_KEYS.LVG_NUMBER]: 'item.taxes.lvg.number',
  ...EXTEND_COMMON_REQUEST_FIELDS
}

export const INTERNATIONAL_TH_ITEMISED_REQUEST_FIELDS = {
  ...COMMON_REQUEST_FIELDS,
  [FIELD_KEYS.QUANTITY]: 'item.quantity',
  [FIELD_KEYS.ITEM_DESCRIPTION]: 'item.description',
  [FIELD_KEYS.ITEM_VALUE]: 'item.unit_value',
  [FIELD_KEYS.ITEM_COLOR]: 'item.color',
  [FIELD_KEYS.ITEM_WEIGHT]: 'item.unit_weight',
  [FIELD_KEYS.COD]: 'delivery.cash_on_delivery',
  [FIELD_KEYS.INSURED_VALUE]: 'delivery.insured_value',
  [FIELD_KEYS.SOURCE_ORDER_ID]: 'source_order_id',
  [FIELD_KEYS.DELIVERY_INSTRUCTION]: 'delivery.delivery_instructions'
}

export const PARCEL_DETAIL_FIELDS = [
  'parcel_details.customs_description',
  'parcel_details.value',
  'parcel_details.origin_country',
  'parcel_details.tax_id',
  'parcel_details.weight'
]

export const ITEM_FIELDS = [
  'item.quantity',
  'item.description',
  'item.unit_value',
  'item.origin_country',
  'item.color',
  'item.unit_weight'
]

export const SG_ITEM_FIELDS = [...ITEM_FIELDS, 'item.taxes.gst.number', 'item.taxes.gst.is_included']

export const MY_ITEM_FIELDS = [...ITEM_FIELDS, 'item.taxes.lvg.number']

export const TO_FIELDS = [
  'to.name',
  'to.address_line1',
  'to.city',
  'to.state_province',
  'to.post_code',
  'to.contact_email',
  'to.contact_number'
]

export const DELIVERY_FIELDS = ['delivery.cash_on_delivery', 'delivery.delivery_instructions', 'delivery.insured_value']

export const NUMBER_FIELDS = [
  'delivery.cash_on_delivery',
  'parcel_details.weight',
  'parcel_details.battery_type',
  'parcel_details.battery_packing',
  'parcel_details.value',
  'parcel_details.hs_code',
  'delivery.insured_value',
  'item.quantity',
  'item.unit_value',
  'item.unit_weight'
]

export const NUMBER_OF_VALID_ROW_ORDERS = 3

export const HEADER_FIELDS_BY_COUNTRY = {
  SG: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.SG.ROW_1)],
  MY: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.MY.ROW_1)],
  VN: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.VN.ROW_1)],
  ID: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.ID.ROW_1)],
  PH: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.PH.ROW_1)],
  TH: ['Row#', ...Object.keys(EXAMPLE_DATA_BY_COUNTRY.TH.ROW_1)]
}

export const ERRORS_TRANSLATION_KEYS = {
  [FIELD_KEYS.REQUESTED_TRACKING_ID]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_requested_tracking_id_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_requested_tid_invalid_message'
  },
  [FIELD_KEYS.TO_NAME]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_recipient_name_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_name_less_than_255_characters'
  },
  [FIELD_KEYS.TO_ADDRESS]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_address_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_address_less_than_255_characters'
  },
  [FIELD_KEYS.TO_CITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_city_is_required_all_except_sg_and_th',
    [ERROR_CODE_MAPPING.INVALID]: 'international_city_less_than_255_characters'
  },
  [FIELD_KEYS.TO_STATE_PROVINCE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: [
      'international_province_is_required_for_country',
      'international_state_is_required_for_country',
      'international_required_all_except_SG_and_VN'
    ],
    [ERROR_CODE_MAPPING.INVALID]: 'international_state_less_than_255_characters'
  },
  [FIELD_KEYS.TO_POSTCODE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_postcode_is_required_all_except_vn',
    [ERROR_CODE_MAPPING.INVALID]: 'the_postcode_must_be_the_valid_postcode_format'
  },
  [FIELD_KEYS.TO_EMAIL]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_email_format'
  },
  [FIELD_KEYS.TO_CONTACT_NUMBER]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_contact_number_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_conact_number_must_be_valid_format'
  },
  [FIELD_KEYS.GOOD_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_goods_description_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'the_description_must_be_maximum_1000_characters'
  },
  [FIELD_KEYS.GOOD_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_goods_value_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'the_value_must_be_number_greate_than_1'
  },
  [FIELD_KEYS.COD]: {
    [ERROR_CODE_MAPPING.INVALID]: 'the_cod_value_must_be_number'
  },
  [FIELD_KEYS.CONSIGNEE_TAX]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_consignee_tax_number_is_required'
  },
  [FIELD_KEYS.QUANTITY]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_quantity_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'the_value_must_be_positive_integer_number_greater_than_1'
  },
  [FIELD_KEYS.ITEM_DESCRIPTION]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_description_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'the_description_must_be_maximum_1000_characters'
  },
  [FIELD_KEYS.ITEM_VALUE]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_value_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'the_value_must_be_number_greate_than_1'
  },
  [FIELD_KEYS.SOURCE_ORDER_ID]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_reference_tracking_id_less_than_255_characters'
  },
  [FIELD_KEYS.GST_NUMBER]: {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_item_gst_number_is_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_gst_number_is_alphabet'
  },
  [FIELD_KEYS.GST_INCLUDED]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_gst_included_invalid_mess'
  },
  [FIELD_KEYS.LVG_NUMBER]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_item_lvg_number_is_alphabet'
  },
  [FIELD_KEYS.DELIVERY_INSTRUCTION]: {
    [ERROR_CODE_MAPPING.INVALID]: 'the_delivery_instructions_must_be_maximum_1000_characters'
  },
  [FIELD_KEYS.INSURED_VALUE]: {
    [ERROR_CODE_MAPPING.INVALID]: 'the_value_must_be_number_greate_than_1'
  },
  [FIELD_KEYS.ITEM_COLOR]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_item_color'
  },
  [FIELD_KEYS.ITEM_WEIGHT]: {
    [ERROR_CODE_MAPPING.INVALID]: 'international_invalid_weight'
  }
}

// Relabel fields only
export const ERRORS_TRANSLATION_KEYS_FOR_RELABEL = {
  'Original Tracking ID': {
    [ERROR_CODE_MAPPING.REQUIRED]: 'international_origin_tid_required',
    [ERROR_CODE_MAPPING.INVALID]: 'international_origin_tid_invalid_message_new',
    [ERROR_CODE_MAPPING.DIFFERENT_ORIGIN]: 'international_origin_tid_different_new_tid'
  },
  'New Requested Tracking ID': {
    [ERROR_CODE_MAPPING.REQUIRED]: 'new_requested_tracking_id_required',
    [ERROR_CODE_MAPPING.INVALID]: 'new_requested_tracking_id_invalid_new'
  }
}

export const FIRST_COL_WIDTH = 100
export const ROW_HEADER_HEIGHT = 100
export const ROW_HEIGHT = 150
export const OVER_SCAN_COL_COUNT = 0
export const OVER_SCAN_ROW_COUNT = 5
export const MARGIN_TOP_MODAL = 350
export const COL_WIDTH = 240
export const HEIGHT_PERCENT = 0.55
export const RIGHT_SPACE_WIDTH = 300
export const CELL_BORDER_WIDTH = 1
export const MAX_HEIGHT = 220
export const FONT_WEIGHT = 400
export const LINE_HEIGHT = 1.5
export const FONT_SIZE = '14px'
export const FONT_FAMILY = 'Lato'
export const CELL_MIN_HEIGHT = 60
export const TOOLTIP_LENGTH_SHOWING = 100

export const CELL_PADDING = {
  LEFT: 15,
  RIGHT: 15,
  TOP: 6,
  BOTTOM: 5,
  SPECIAL: 45
}

export const NUMBER_OF_SHOWING_LINE = {
  MIN: 4,
  NORMAL: 5,
  MEDIUM: 6,
  MAX: 7
}

export const CELL_POSITION = {
  CENTER: 'CENTER',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

export const COMMON_COLUMN_INDEX = {
  0: NUMBER_OF_SHOWING_LINE.MAX, // TO_NAME
  1: NUMBER_OF_SHOWING_LINE.MAX, // TO_ADDRESS
  2: NUMBER_OF_SHOWING_LINE.MAX, // TO_CITY
  3: NUMBER_OF_SHOWING_LINE.MAX, // TO_STATE_PROVINCE
  4: NUMBER_OF_SHOWING_LINE.MIN, // TO_POSTCODE
  5: NUMBER_OF_SHOWING_LINE.NORMAL, // TO_EMAIL
  6: NUMBER_OF_SHOWING_LINE.MIN // TO_CONTACT_NUMBER
}

export const COLUMN_INDEX = {
  ...COMMON_COLUMN_INDEX,
  7: NUMBER_OF_SHOWING_LINE.MEDIUM, // GOOD_DESCRIPTION
  8: NUMBER_OF_SHOWING_LINE.MEDIUM, // GOOD_VALUE
  9: NUMBER_OF_SHOWING_LINE.MEDIUM, // COD
  10: NUMBER_OF_SHOWING_LINE.MEDIUM // SOURCE_ORDER_ID
}

export const ITEMISED_COLUMN_INDEX = {
  ...COMMON_COLUMN_INDEX,
  7: NUMBER_OF_SHOWING_LINE.MAX, // CONSIGNEE_TAX_NUMBER
  8: NUMBER_OF_SHOWING_LINE.NORMAL, // ITEM_QUANTITY
  9: NUMBER_OF_SHOWING_LINE.MEDIUM, // ITEM_DESCRIPTION
  10: NUMBER_OF_SHOWING_LINE.NORMAL, // ITEM_VALUE
  11: NUMBER_OF_SHOWING_LINE.MIN, // COD
  12: NUMBER_OF_SHOWING_LINE.MEDIUM // SOURCE_ORDER_ID
}

export const ORDER_TYPE = {
  ITEMISED: 'Itemised',
  NORMAL: 'Normal'
}

export const RULE_TYPES = {
  MIN_LENGTH: 'minLen',
  MAX_LENGTH: 'maxLen',
  EQUAL_MAX_LENGTH: 'equalMax',
  REGEX: 'regex',
  MIN: 'min',
  MAX: 'max',
  DUPLICATE: 'duplicate',
  TRUTHY_FALSY: 'truthyFalsy',
  EQUAL_MIN: 'equalMin',
  IN: 'in'
}

export const VALIDATION_FORM = {
  INVALID: 'invalid',
  DUPLICATE: 'duplicate',
  DIFFERENT_ORIGIN: 'difference_origin'
}

export const COLUMNS_BY_COUNTRY = {
  SG: [
    { title: 'goods_value', span: 5, renderKey: 'goodsValue', position: 'right' },
    { title: 'gst_number', span: 5, renderKey: 'gstNumber', position: 'left' },
    { title: 'quantity', span: 3, renderKey: 'quantityValue', position: 'right' },
    { title: 'customs_description_short', span: 10, renderKey: 'customsDescription', position: 'left' }
  ],
  MY: [
    { title: 'goods_value', span: 5, renderKey: 'goodsValue', position: 'right' },
    { title: 'lvg_registration_number', span: 5, renderKey: 'lvgNumber', position: 'left' },
    { title: 'quantity', span: 3, renderKey: 'quantityValue', position: 'right' },
    { title: 'customs_description_short', span: 10, renderKey: 'customsDescription', position: 'left' }
  ],
  ID: [
    { title: 'goods_value', span: 5, renderKey: 'goodsValue', position: 'right' },
    { title: 'quantity', span: 3, renderKey: 'quantityValue', position: 'right' },
    { title: 'customs_description_short', span: 15, renderKey: 'customsDescription', position: 'left' }
  ],
  VN: [
    { title: 'goods_value', span: 5, renderKey: 'goodsValue', position: 'right' },
    { title: 'quantity', span: 3, renderKey: 'quantityValue', position: 'right' },
    { title: 'customs_description_short', span: 15, renderKey: 'customsDescription', position: 'left' }
  ],
  TH: [
    { title: 'goods_value', span: 4, renderKey: 'goodsValue', position: 'right' },
    { title: 'quantity', span: 3, renderKey: 'quantityValue', position: 'right' },
    { title: 'item_color', span: 5, renderKey: 'color', position: 'left' },
    { title: 'weight_kg', span: 4, renderKey: 'weight', position: 'right' },
    { title: 'customs_description_short', span: 7, renderKey: 'customsDescription', position: 'left' }
  ]
}

export const LEARN_MORE_LINK =
  'https://docs.google.com/presentation/d/1yS0FJSucxv6Px-owtZHBW05Es-bgSBKNlEIj7_tc5VM/edit?usp=sharing'

export const BATCH_ORDER_CREATION_TYPE = {
  CORPORATE: 'Corporate',
  CORPORATE_INTERNATIONAL: 'CorporateInternational',
  CORPORATE_RELABEL: 'CorporateRelabel',
  RELABEL: 'Relabel'
}
